import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import { Binder, MiddlewareFactory } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ContentRowTemplate from '../../../components/ContentRow/ContentRowTemplate/ContentRowTemplate';
import useInfiniteQueryTemplate from '../../../helpers/hooks/useInfiniteQueryTemplate/useInfiniteQueryTemplate';
import { MIDDLEWARE_STRATE } from '../../../helpers/oneNavigation/middleware';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { ContentStrateV5, DisplayParameters } from '../../../templates/LandingV5/data/formatter';
import { getContentRowStandardNextPageUrl } from '../data/getContentRowStandardNextPageUrl';
import { IContentRowStandardState } from '../data/types';

export type ContentRowStandardContainerProps = {
  contents: ContentStrateV5[];
  displayParameters?: DisplayParameters;
  header: ContentRowHeader;
  isFromDetail?: boolean;
  itemOffset?: number;
  middleware?: MiddlewareFactory[];
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  URLNextPage?: string;
} & FromProp;

function ContentRowStandardContainer({
  contents,
  displayParameters,
  from,
  header,
  isFromDetail = false,
  itemOffset,
  middleware,
  onClickParameters,
  onFocusable,
  URLNextPage = undefined,
}: ContentRowStandardContainerProps): JSX.Element {
  const isFromLanding = from === Template.Landing;
  const isFromShowcase = from === Template.Showcase;

  const isTvDevice = useSelector(displayTVModeSelector);

  const [{ data, isFetchingNextPage, fetchNextPage, hasNextPage }] = useInfiniteQueryTemplate<IContentRowStandardState>(
    undefined,
    { from, template: FetchRequestTypes.ContentRowStandard, onClickParameters },
    {
      // We don't want to run the fetch when the component is initialized
      // because we are already receiving the first contents from props that we pass through the initialData
      enabled: false,
      initialData: {
        pages: [
          {
            contents,
            URLNextPage,
          },
        ],
        pageParams: [],
      },
      getNextPageParam: (lastPageData) => getContentRowStandardNextPageUrl(lastPageData),
    }
  );

  const allContents = data?.pages.reduce<ContentStrateV5[]>((acc, page) => [...acc, ...page.contents], []);

  const fetchNextContents = useCallback(async () => {
    if (!isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <Binder middleware={middleware || MIDDLEWARE_STRATE}>
      <ContentRowTemplate
        contents={allContents}
        displayParameters={displayParameters}
        fetchNextContents={fetchNextContents}
        header={header}
        isFromDetail={isFromDetail}
        isFromLanding={isFromLanding}
        isFromShowcase={isFromShowcase}
        isTvDevice={isTvDevice}
        itemOffset={itemOffset}
        onFocusable={onFocusable}
      />
    </Binder>
  );
}

export default memo(ContentRowStandardContainer);
