import { addQueryParam } from '@canalplus/mycanal-commons';
import { DEFAULT_NB_ITEM_FETCHED } from '../../../constants/strates';

export function getContentRowStandardNextPageUrl<T extends { URLNextPage?: string }>(
  data: T,
  dataPerPage: number = DEFAULT_NB_ITEM_FETCHED
): string | undefined {
  if (!data.URLNextPage) {
    return;
  }

  return addQueryParam(data.URLNextPage, 'get', String(dataPerPage));
}
